<template>
  <div class="StaffManage-list">
    <GlobalInfoBar
      title="门店红包统计"
      content="统计门店商家获得员工红包和提现成功金额，可查看商家提现明细。"
    />
    <div class="button_top">
      <div class="faultBtn">
          <div :class="this.isManager == false ? 'left' : 'right'" @click="BtnClick(false)">
            门店人员
          </div>
          <div :class="this.isManager == true ? 'left' : 'right'" @click="BtnClick(true)">
            管理人员
          </div>
      </div>
    </div>
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <StaffManageFrom :isManager="isManager" />
      </div>
      <StaffManageTable :isManager="isManager" />
    </GlobalChunk>
  </div>
</template>

<script >
export default {
  components: {
    StaffManageFrom: () => import("./from"),
    StaffManageTable: () => import("./table"),
  },
  data() {
    return {
      isManager: false,
      formItemList: [
        {
          key: "staffName",
          type: "input",
          labelName: "职员名称",
          placeholder: "请输入职员名称",
        },
        {
          key: "staffMobile",
          type: "input",
          labelName: "联系电话",
          placeholder: "请输入联系电话",
        },
        { slotName: "operationSelect" },
        { slot: "AddSlot" },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    BtnClick(isManager) {
      this.isManager = isManager
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.StaffManage-list {
  .button_top {
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn {
    width: 280px;
    height: 42px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    .left {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 40px;
      height: 40px;
      background: #0981ff;
      border-radius: 20px;
    }
    .right {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
}
</style>
